import {Inject, Injectable} from '@angular/core';
import {
  AppSettings,
  OrganizationUnit,
  OrganizationUnitType,
  SdsCommunity,
  SdsNamespace,
  SdsStream,
  SETTINGS
} from '~/models';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Computation, ComputationMsg, ComputationState, ComputationStateStr} from '~/models/computation';
import {ErrorService} from '~/services/error.service';
import {APIResult} from '~/models/APIResult';
import {TimeData} from '~/models/timeData';
import { LoaderService } from '~/services/loader.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  // APIURL
  constructor(@Inject(SETTINGS) public settings: AppSettings,
              private errorService: ErrorService,
              public http: HttpClient,
              private loaderService: LoaderService // Added this line
  ) { }

  private getURL(): string {
    return this.settings.APIURL;
  }
  private handleError(error: HttpErrorResponse, errorValue: any): Observable<any> {
    const data = error.error;
    console.error('error', error, data.Error);
    this.errorService.handleError(data.Error ?? error.message);
    return of(errorValue);
  }
  private handleResponse<T>(observable: Observable<T>, errorValue: T): Observable<any> {
    this.loaderService.show(); // Show loader
    return observable.pipe(
      map((response) => {
        this.loaderService.hide(); // Hide loader before returning response
        return response;
      }),
      catchError((error) => {
        this.loaderService.hide(); // Hide loader on error
        return this.handleError(error, errorValue);
      })
    );
  }
  getGraph(namespaceId: string, computationId: number): Observable<string> {
    return this.handleResponse(this.http.get(`${this.getURL()}/Computation/GetGraph?namespaceId=${namespaceId}&computationId=${computationId}`), null);
  }
  getComputationById(namespaceId: string, computationId: number): Observable<Computation> {
    return this.handleResponse(this.http.get(`${this.getURL()}/Computation/GetById?namespaceId=${namespaceId}&computationId=${computationId}`), null);
  }
  getComputations(namespaceId: string): Observable<Computation[]> {
    return this.handleResponse(this.http.get(`${this.getURL()}/Computation/Get?namespaceId=${namespaceId}`), []);
  }
  removeComputation(namespaceId: string, computationId: number): Observable<Computation[]> {
    //confirm
    if (window.confirm('Are you sure you want to delete this computation?')) {
      const removeStream = window.confirm('Do you want to remove the stream associated with this computation?');
      return this.handleResponse(this.http.delete(`${this.getURL()}/Computation/Delete?namespaceId=${namespaceId}&computationId=${computationId}&removeStream=${removeStream}`), null);
    }
    return of(null);
  }
  startComputation(namespaceId: string, computationId: number): Observable<Computation[]> {
    //confirm
    if (window.confirm('Are you sure you want to start this computation?')) {
      return this.handleResponse(this.http.get(`${this.getURL()}/Computation/Start?namespaceId=${namespaceId}&computationId=${computationId}`), null);
    }
    return of(null);
  }
  stopComputation(namespaceId: string, computationId: number): Observable<Computation[]> {
    //confirm
    if (window.confirm('Are you sure you want to stop this computation?')) {
      return this.handleResponse(this.http.get(`${this.getURL()}/Computation/Stop?namespaceId=${namespaceId}&computationId=${computationId}`), null);
    }
    return of(null);
  }
  getDataComputation(computation: Computation): Observable<APIResult<TimeData[]>> {
    const namespaceId = computation.NamespaceId;
    const computationId = computation.ComputationId;
    return this.handleResponse(this.http.get(`${this.getURL()}/Computation/GetData?namespaceId=${namespaceId}&computationId=${computationId}`), null);
  }
  errorMessages(computation: Computation): Observable<APIResult<ComputationMsg>> {
    return this.handleResponse(this.http.post(`${this.getURL()}/Computation/ErrorMessagesStr`, computation), null);
  }
  showComputation(computation: Computation): Observable<APIResult<TimeData[]>> {
    return this.handleResponse(this.http.post(`${this.getURL()}/Computation/Show`, computation), null);
  }
  createComputation(computation: Computation): Observable<APIResult<number>> {
    return this.handleResponse(this.http.post(`${this.getURL()}/Computation/Create`, computation), null);
  }
  updateComputation(computation: Computation): Observable<APIResult<number>> {
    return this.handleResponse(this.http.post(`${this.getURL()}/Computation/Update`, computation), null);
  }

  getNamespaces(): Observable<OrganizationUnit[]> {
    return this.http.get<SdsNamespace[]>(`${this.getURL()}/Datahub/Namespaces`).pipe(
      map((r) =>
        (r as SdsNamespace[]).map((r) => {
          return { Unit: r, Type: OrganizationUnitType.Namespace } as OrganizationUnit;
        })
      ),
      catchError((error) => this.handleError(error, []))
    );
  }
  getCommunities(): Observable<OrganizationUnit[]> {
    return this.http.get<SdsCommunity[]>(`${this.getURL()}/Datahub/Communities`).pipe(
      map((r) =>
        (r as SdsCommunity[]).map((r) => {
          return { Unit: r, Type: OrganizationUnitType.Community } as OrganizationUnit;
        })
      ),
      catchError((error) => this.handleError(error, []))
    );
  }
  getStreams(namespaceId: string): Observable<SdsStream[]> {
    const url = `${this.getURL()}/Datahub/Streams?namespaceId=${namespaceId}`;
    return this.http.get<SdsStream[]>(url).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => this.handleError(error, []))
    );
  }
}
