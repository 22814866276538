import { Injectable, signal } from '@angular/core';
import { SdsStream } from '~/models';
import {toObservable} from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class StreamsService {
  public streamsSignal = signal<SdsStream[]>([]);
  public streamsStrSignal = signal<string[]>([]);
  public streamsStrSignal$ = toObservable(this.streamsStrSignal);

  clearStreams() {
    this.streamsSignal.set([]);
    this.streamsStrSignal.set([]);
  }

  setStreams(r: SdsStream[]) {
    this.streamsSignal.set(r);
    this.streamsStrSignal.set(r.map((stream) => stream.Name));
  }
}
