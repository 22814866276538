import {Component, inject, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatButton, MatButtonModule} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {NamespacesService, OidcService} from '~/services';
import {MatTooltip} from '@angular/material/tooltip';
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from '@angular/material/autocomplete';
import {MatFormField, MatInput, MatLabel, MatSuffix} from '@angular/material/input';
import {AsyncPipe, NgForOf, NgIf} from '@angular/common';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {MatList, MatListItem} from '@angular/material/list';
import {MatLine} from '@angular/material/core';
import {AutocompleteFormComponent} from '~/components/form/autocomplete-form/autocomplete-form.component';
import {Observable} from 'rxjs';
import {OrganizationUnit} from '~/models';
import {TranslateModule} from '@ngx-translate/core';

export const LSKEYNAMESPACE = 'namespace';

@Component({
  selector: 'namespace-dialog',
  template: `
    <button [disabled]="disabled" mat-button class="namespace-btn" (click)="openDialog()" matTooltip="{{ 'Change Namespace' | translate }}">
      <div style="float: left; margin-top: 9px;">{{this.value?.Unit?.Name}}</div>
      <div style="float: right; margin-top: 12px;"><mat-icon>arrow_drop_down</mat-icon></div>
    </button>
  `,
  styleUrls: ['namespace-dialog.scss'],
  standalone: true,
  imports: [
    MatButton,
    MatIcon,
    MatTooltip,
    TranslateModule
  ],
})
export class NamespaceDialog implements OnInit {
  readonly dialog = inject(MatDialog);
  namespacesService = inject(NamespacesService);
  oidc = inject(OidcService);

  value: OrganizationUnit = null;
  disabled = false;

  ngOnInit(): void {
    this.namespacesService.disabled$.subscribe((value) => {
      this.disabled = value;
    });

    // const namespaceStr = localStorage.getItem(LSKEYNAMESPACE);
    // if (namespaceStr) {
    //   this.value = JSON.parse(namespaceStr) as OrganizationUnit;
    //   this.namespacesService.setNameSpace(this.value);
    //   console.log('namespace', namespaceStr, this.value);
    // }
    // else {
      this.namespacesService.getNamespaces();
      this.namespacesService.organizationUnits$.subscribe((value) => {
        const namespaceStr = localStorage.getItem(LSKEYNAMESPACE);
        if (namespaceStr) {
          this.value = JSON.parse(namespaceStr) as OrganizationUnit;
          this.namespacesService.setNameSpace(this.value);
        }
        else if (value?.length > 0)
          {this.namespacesService.setNameSpace(value[0]);}
      });
      this.oidc.authSignal.subscribe((value) => {
        if (this.oidc.isLoggedIn()) {
          this.namespacesService.getNamespaces();
        }
      });
    // }
    this.namespacesService.organizationUnitSelected$.subscribe((value) => {
      if (value) {
        this.value = value;
      }
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(NamespaceContentDialog);

    dialogRef.afterClosed().subscribe((result) => {
    });
  }
}

@Component({
  selector: 'namespace-content-dialog',
  templateUrl: 'namespace-content-dialog.html',
  standalone: true,
  imports: [
    MatDialogModule, MatButtonModule, MatAutocompleteTrigger, MatIcon, MatInput, MatSuffix, NgIf, ReactiveFormsModule, MatFormField, MatLabel, MatList, MatListItem, MatLine, NgForOf, AsyncPipe, MatOption, AutocompleteFormComponent, MatAutocomplete, TranslateModule
  ],
})
export class NamespaceContentDialog implements OnInit, OnChanges, OnDestroy {
  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }
  namespacesService = inject(NamespacesService);
  options: OrganizationUnit[] = [];
  formControl: FormControl = new FormControl();
  filteredOptions: Observable<OrganizationUnit[]>;
  subscribe: any;
  readonly dialogRef = inject(MatDialogRef<NamespaceContentDialog>);

  ngOnInit() {
    this.namespacesService.getNamespaces();
    this.subscribe = this.namespacesService.organizationUnits$.subscribe((value) => {
      this.options = value?.map((x) => x) ?? [];
    });

    // this.filteredOptions = this.formControl.valueChanges.pipe(
    //   startWith(''),
    //   map(value => {
    //     return this._filter(value || '')
    //   })
    // );
    // this.options.subscribe((value) => { this.options = value; });
  }
  ngOnChanges() {
    this.formControl?.setValue(this.formControl.value);
  }
  // private _filter(value: string): OrganizationUnit[] {
  //   const filterValue = value?.toLowerCase() ?? '';
  //
  //   return this.options.filter(option => option.Unit.Name.toLowerCase().includes(filterValue));
  // }

  // onNoClick(): void {
  //   // this.dialogRef.close();
  // }
  selectOptions(namespace: OrganizationUnit) {
    this.dialogRef.close();
    this.namespacesService.requestedStreams = false;
    this.namespacesService.setNameSpace(namespace);
  }
}
