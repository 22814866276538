import {inject, Injectable} from '@angular/core';
import {SdsService} from '~/services/sds.service';
import {OrganizationUnit} from '~/models';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import { map } from 'rxjs/operators';
import {LSKEYNAMESPACE} from '~/components/toolbar/namespace-dialog';
import {OidcService} from '~/services/oidc.service';
import {StreamsService} from '~/services/streams.service';
import {Router} from '@angular/router';
import {ApiService} from '~/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class NamespacesService {
  private organizationUnitsSubject = new BehaviorSubject<OrganizationUnit[]>(null);
  organizationUnitSelectedSubject = new BehaviorSubject<OrganizationUnit>(null);
  disabled = new BehaviorSubject<boolean>(false);
  disabled$ = this.disabled.asObservable();

  organizationUnits$ = this.organizationUnitsSubject.asObservable();
  organizationUnitSelected$ = this.organizationUnitSelectedSubject.asObservable();

  api = inject(ApiService);
  sds = inject(SdsService);
  oidc = inject(OidcService);
  streamsService = inject(StreamsService);
  router = inject(Router);

  requested = false;
  requestedStreams = false;

  public setNameSpace(namespace: OrganizationUnit): void {
    this.organizationUnitSelectedSubject.next(namespace);
    localStorage.setItem(LSKEYNAMESPACE, JSON.stringify(namespace));
    if (namespace) {
      this.queryStreams(namespace, '');
    } else {
      this.streamsService.clearStreams(); // Use StreamsService to clear streams
    }
  }
  queryStreams(organizationUnit: OrganizationUnit, query: string): void {
    if (!this.oidc.isLoggedIn() || this.requestedStreams) {return;}
    this.requestedStreams = true;
    this.api.getStreams(organizationUnit.Unit.Id)
      .subscribe((r) => {
        this.streamsService.setStreams(r); // Use StreamsService to set streams
      });
  }

  public getNamespaces(): void {
    if (!this.oidc.isLoggedIn() && this.requested) {
      this.oidc.cleanLogin();
      this.router.navigate(['/login']);
    }
    if (!this.oidc.isLoggedIn() || this.requested) {return;}
    if (this.organizationUnitsSubject.value === null) {
      this.requested = true;
      combineLatest([
        this.sds.getNamespaces(),
        this.sds.getCommunities()
      ]).pipe(
        map(([namespaces, communities]) => [...namespaces, ...communities])
      ).subscribe((units) => {
        this.organizationUnitsSubject.next(units);
      });
    }
    this.organizationUnitsSubject.next(this.organizationUnitsSubject.value);
  }

  public getNamespaceId(): string {
    return this.organizationUnitSelectedSubject.value?.Unit.Id;
  }

}
