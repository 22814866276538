import { DisplayType } from '../display-type';
import { SdsTypeCode } from './sds-type-code';
import { SdsTypeCodeNumeric } from './sds-type-code-numeric';

/** These SDS Type Codes are supported by the application */
export const SdsTypeCodeMap = {
  [SdsTypeCode.Object]: DisplayType.None,
  [SdsTypeCodeNumeric.Object]: DisplayType.None,
  [SdsTypeCode.Int16]: DisplayType.Number,
  [SdsTypeCodeNumeric.Int16]: DisplayType.Number,
  [SdsTypeCode.UInt16]: DisplayType.Number,
  [SdsTypeCodeNumeric.UInt16]: DisplayType.Number,
  [SdsTypeCode.Int32]: DisplayType.Number,
  [SdsTypeCodeNumeric.Int32]: DisplayType.Number,
  [SdsTypeCode.UInt32]: DisplayType.Number,
  [SdsTypeCodeNumeric.UInt32]: DisplayType.Number,
  [SdsTypeCode.Int64]: DisplayType.Number,
  [SdsTypeCodeNumeric.Int64]: DisplayType.Number,
  [SdsTypeCode.UInt64]: DisplayType.Number,
  [SdsTypeCodeNumeric.UInt64]: DisplayType.Number,
  [SdsTypeCode.Single]: DisplayType.Number,
  [SdsTypeCodeNumeric.Single]: DisplayType.Number,
  [SdsTypeCode.Double]: DisplayType.Number,
  [SdsTypeCodeNumeric.Double]: DisplayType.Number,
  [SdsTypeCode.Decimal]: DisplayType.Number,
  [SdsTypeCodeNumeric.Decimal]: DisplayType.Number,
  [SdsTypeCode.DateTime]: DisplayType.DateTime,
  [SdsTypeCodeNumeric.DateTime]: DisplayType.DateTime,
  [SdsTypeCode.NullableInt16]: DisplayType.Number,
  [SdsTypeCodeNumeric.NullableInt16]: DisplayType.Number,
  [SdsTypeCode.NullableUInt16]: DisplayType.Number,
  [SdsTypeCodeNumeric.NullableUInt16]: DisplayType.Number,
  [SdsTypeCode.NullableInt32]: DisplayType.Number,
  [SdsTypeCodeNumeric.NullableInt32]: DisplayType.Number,
  [SdsTypeCode.NullableUInt32]: DisplayType.Number,
  [SdsTypeCodeNumeric.NullableUInt32]: DisplayType.Number,
  [SdsTypeCode.NullableInt64]: DisplayType.Number,
  [SdsTypeCodeNumeric.NullableInt64]: DisplayType.Number,
  [SdsTypeCode.NullableUInt64]: DisplayType.Number,
  [SdsTypeCodeNumeric.NullableUInt64]: DisplayType.Number,
  [SdsTypeCode.NullableSingle]: DisplayType.Number,
  [SdsTypeCodeNumeric.NullableSingle]: DisplayType.Number,
  [SdsTypeCode.NullableDouble]: DisplayType.Number,
  [SdsTypeCodeNumeric.NullableDouble]: DisplayType.Number,
  [SdsTypeCode.NullableDecimal]: DisplayType.Number,
  [SdsTypeCodeNumeric.NullableDecimal]: DisplayType.Number,
  [SdsTypeCode.NullableDateTime]: DisplayType.DateTime,
  [SdsTypeCodeNumeric.NullableDateTime]: DisplayType.DateTime,
};
