import {Inject, Injectable} from '@angular/core';
import { User, UserManager } from 'oidc-client-ts';
import { from, Observable, of, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { AppSettings, SETTINGS } from '~/models';
import {Router} from '@angular/router';
import {LSKEYNAMESPACE} from '~/components/toolbar/namespace-dialog';

@Injectable({ providedIn: 'root' })
export class OidcService {
  userManager: UserManager;
  authHeaders: Record<string, string | string[]>;
  LS_KEY_AUTH = 'auth';
  public authSignal: Subject<string> = new Subject<string>();

  constructor(@Inject(SETTINGS) public settings: AppSettings, public router: Router) {
    const dataSettings = {
      authority: `${settings.Resource}/identity`,
      client_id: settings.ClientId,
      response_type: 'code',
      scope: 'openid profile email ocsapi',
      redirect_uri: `${window.location.origin}/oauth2callback`,
      post_logout_redirect_uri: `${window.location.origin}/callback-logout`,
      acr_values: `tenant:${settings.TenantId}`,
      silent_redirect_uri: `${window.location.origin}/oauth2callback`,
      automaticSilentRenew: true,
      // userStore: new WebStorageStateStore({ store: window.localStorage }),
    };
    this.userManager = new UserManager(dataSettings);
  }


  /** Start the login process */
  login() {
    return this.userManager.signinRedirect();
  }

  rmLSStartsWith = (prefix) => {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith(prefix)) {
        localStorage.removeItem(key);
      }
    }
  };

// Example usage:
  /** Start the logout process */
  cleanLogin(): void {
    localStorage.removeItem(this.LS_KEY_AUTH);
    this.rmLSStartsWith('oidc.');
    // localStorage.removeItem(LSKEYNAMESPACE);

    this.userManager.removeUser();
  }
  logout(): void {
    this.userManager.signoutRedirect();
    // this.userManager.signoutRedirectCallback().then((e) => {
    //   this.router.navigate(['/login']);
    // }).catch((e) => {
    //   console.log('error', e);
    // });
  }

  /** Handle an OIDC redirect callback, and set auth headers */
  signinRedirectCallback(): Observable<User> {
    return from(this.userManager.signinRedirectCallback()).pipe(
      tap((user) => this.setAuthHeaders(user))
    );
  }

  /** Handle an OIDC silent redirect callback, and set auth headers */
  signinSilentCallback(): Observable<User> {
    // console.log('Warning ! signinSilentCallback => signinPopup');
    return from(this.userManager.signinPopup()).pipe(
      tap((user) => this.setAuthHeaders(user))
    );
  }

  /** Get the already logged in user, and set auth headers */
  getUser(): Observable<User> {
    const userStr = localStorage.getItem(this.LS_KEY_AUTH);
    if (userStr) {
      const user = JSON.parse(userStr);
      this.setAuthHeaders(user);
    }
    return from(this.userManager.getUser()).pipe(
      tap((user) => this.setAuthHeaders(user))
    );
  }

  /** Check whether there is a valid logged in user */
  checkAuth(): Observable<boolean> {
    return this.getUser().pipe(
      map((user) => user != null && !user.expired),
      catchError(() => of(false))
    );
  }

  /** Set auth headers for use with http requests */
  setAuthHeaders(user: User): void {
    if (!user) {
      this.authHeaders = null;
      return;
    }
    this.authHeaders = user && {
      Authorization: `${user.token_type} ${user.access_token}`,
      IdToken: `${user.id_token}`,
      AccessToken: `${user.access_token}`,
    };
    const str = JSON.stringify(user);
    localStorage.setItem(this.LS_KEY_AUTH, str);
    this.authSignal.next(str);
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem(this.LS_KEY_AUTH);
  }
}
